<template>
  <div class="GalleryCategoryContent">
    <form class="NewCategory" enctype="multipart/form-data" v-on:submit.prevent>
      <div dpadding tcenter>
        <ImageFileInput name="cover" :value="image" :readonly="isdelete" mauto />
        <Spacer num=".5" />
        <div strong>{{ $locale["image"] }}</div>
        <Spacer num=".5" />
        <Input type="text" name="name" :label="$locale['category_name']" :required="true" :value="name" :readonly="isdelete" />
      </div>
      <input type="hidden" :name="`${isdelete ? 'id' : 'category'}`" v-if="edit || isdelete" :value="_id" />
    </form>
    <div dpadding v-sticky="{ bottom: 0 }">
      <Confirm :confirm="confirm" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal", "edit", "isdelete"],
  data: function() {
    return {
      axios: {
        timeout: 1000 * 60 * 30,
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    createCategory: async function() {
      this.loading = await this.loader(this.$locale["creating_category"] + "...");
      this.endpoint = `gallery/createCategory`;

      if (this.isdelete) {
        this.endpoint = `gallery/delete`;
      }

      await Go.sleep(APP_TRANSITION_TIME);

      try {
        const formData = new FormData(document.querySelector(".NewCategory"));
        this.req = await this.$http.post(`${this.$apiHost}${this.endpoint}`, formData, this.axios);
        this.response(this.req.data);
      } catch (error) {
        this.error(error);
      }
    },
    error: function(error) {
      this.loading.close(() => {
        this.alert(Go.getErrorMessage(error));
      });
    },
    response: function(data = {}) {
      if (!data.success) {
        return this.loading.close(async () => {
          this.alert(data);
        });
      }

      return this.loading.close(async () => {
        this.modal.close(() => {
          this.$store.state.feedKey++;
          this.$store.state.appKey++;
        });
        if (data.message) {
          this.alert(data);
        }
      });
    },
  },
  computed: {
    _id: function() {
      if (this.edit || this.isdelete) {
        return this.$state["gallery_category"] && this.$state["gallery_category"]["_id"];
      }
    },
    name: function() {
      if (this.edit || this.isdelete) {
        return this.$state["gallery_category"] && this.$state["gallery_category"]["name"];
      }
    },
    image: function() {
      if (this.edit || this.isdelete) {
        return this.$state["gallery_category"] && this.$state["gallery_category"]["file"]["src"];
      }
    },
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
          action: () => {
            this.modal.close();
          },
        },
        accept: {
          label: this.isdelete ? this.$locale["delete_category"] : this.$locale["save_category"],
          action: () => {
            this.createCategory();
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.GalleryCategoryContent {
  .imageFileInput {
    @include Flex(center, center, center);
    @include fixedSize(156px);
    border-radius: 50%;
    background-color: $alto;
  }
}
</style>
